import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, lastValueFrom, map, merge, mergeMap, of, switchMap, take, zip } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AdvancedSettingsService } from '../../advanced-settings/services/advanced-settings.service';
import { availableCms, availableDatapointPlacement, dataPointsValues } from '../../../core/constants/constants';
import { DataPointService } from '../../data-point-manager/services/data-point.service';
import { DataPoint } from '../../data-point-manager/models/data-point';
import { UcVersion } from '../../../shared/models/uc-version.enum';
interface PriewData {
  deviceHash: string;
  patronId: string;
  templateToken: string;
}

enum CmsName {
  IGT = '0',
  Envoy = '1',
  Local = '3',
  'IGT(v2)' = '4',
  'OLKG' = '5',
}

@Injectable({
  providedIn: 'root'
})
export class BuilderService {

  public availableDataPoints: { [key: string]: DataPoint[] } = {};

  public ucVersion: string = '';

  public cms = '';

  constructor(
    private http: HttpClient,
    private advancedSettingsService: AdvancedSettingsService,
    private dataPointService: DataPointService
  ) {
  }

  public mapDatapoints() {
    const observbles = [this.getCmsTypeAndVersion(), this.dataPointService.getDataPointsConfiguration()];
    zip(observbles).subscribe({
      next: ([cmsConfig, dataPoints]) => {
        const { type, ucVersion } = cmsConfig as any;
        this.cms = type;
        this.ucVersion = ucVersion;
        const builderDatapoints = availableDatapointPlacement.map(v => v.value);
        const filteredCmsByVersion = availableCms.filter(cms => cms.version === this.ucVersion);
        this.processDatapoints((dataPoints as DataPoint[]), builderDatapoints, filteredCmsByVersion);
      },
      error: err => {
        throw err
      }
    });
  }

  private getCmsTypeAndVersion() {
    if (!!this.cms && !!this.ucVersion) {
      return of({
        type: this.cms,
        ucVersion: this.ucVersion
      });
    }
    return this.advancedSettingsService.getCmsValue();
  }

  private processDatapoints(dataPoints: DataPoint[], builderDatapoints: any[], filteredCmsByVersion: any[]) {
    this.availableDataPoints = {};
    builderDatapoints.forEach((datapointKey) => {
      const datapointFilter = (datapointKey: string) => {
        const dataPointsArray = dataPoints.filter((v: any) => {
          const currentCms = (this.ucVersion === UcVersion.Version_2 && this.cms === 'IGT') ? 'IGT(v2)' : this.cms;
          const cmsName: any = CmsName;
          return filteredCmsByVersion.some(item => v?.cms?.includes(cmsName[currentCms])) && v?.available?.includes(datapointKey);
        });
        return dataPointsArray.sort((a: any, b: any) => a.name < b.name ? -1 : 1)
      };
      Object.defineProperty(this.availableDataPoints, datapointKey, { enumerable: true, value: datapointFilter(datapointKey) });
    });
    this.availableDataPoints.tierLevelCard.push({
      "id": "image",
      "name": "Image",
      "available": [
        "tierLevelCard",
      ],
      "cms": [
        "0",
        "1",
        "3",
        "4"
      ],
    } as any)
  }

  public saveGlobalVariables(id: string, json: string) {
    return this.http.put(`${environment.apiUrl}Configuration/${id}`, { jsonContent: json })
  }

  public createGlobalVariables() {
    return this.http.post(`${environment.apiUrl}Configuration`, { jsonContent: "{}" });
  }

  public getGlobalVariables() {
    return this.http.get(`${environment.apiUrl}Configuration`)
  }

  public getGlobalVariablesInstance() {
    return this.getGlobalVariables().pipe(
      switchMap((result) => {
        if (!(result as any).length) {
          return this.createGlobalVariables().pipe(switchMap(() => {
            return this.getGlobalVariables().pipe(map((res) => (res as any)[0]))
          }));
        } else {
          return of((result as any[])[0])
        }
      })
    )
  }

  public getPreviewUrl(templateId: string, patronId: string): Observable<string> {
    const url = `${environment.apiUrl}template/${templateId}/preview-link`;
    const params = new HttpParams().append('patronId', patronId);

    return this.http.get<PriewData>(url, {
      params
    }).pipe(
      map((data) => {
        let params = new HttpParams();

        params = params.append('kioskId', data.templateToken);
        params = params.append('h', data.deviceHash);
        params = params.append('patronId', patronId);

        return `${environment.previewUrl}?${params.toString()}`;
      })
    );
  }

  public getBuilderTemplate() {
    return this.http.get(`${environment.apiUrl}Template`)
  }

  public createBuilderTemplate() {
    return this.http.post(`${environment.apiUrl}Template`, { jsonContent: "{}" });
  }

  public saveBuilderTemplate(id: string, json: string) {
    return this.http.put(`${environment.apiUrl}Template/${id}`, { jsonContent: json })
  }

  public getBuilderInstance() {
    return this.getBuilderTemplate().pipe(
      switchMap((result) => {
        if (!(result as any).length) {
          return this.createBuilderTemplate().pipe(map(res => res));
        } else {
          return of((result as any[])[0])
        }
      })
    )
  }

  private getDatapointValues() {
    return dataPointsValues;
  }
}



