
export enum TemplateName {
  PDF = 'PDF',
  Profile = 'Profile',
  Webpage = 'Webpage',
  Offers = 'Offers',
  OffersCms = 'Offers(Cms)',
  OffersAndDrawings = 'Offers & Drawings',
  WinAndLoss = 'Win / Loss',
  DrawingCms = 'Drawing(Cms)',
  GiftCard = 'Gift Cards',
  OffersAndOffersCms = 'Offers & Offers(Cms)',
  OffersCmsOffersAndDrawings = 'Offers, Offers(Cms) & Drawings',
  Redemptions = 'Redemptions',
  Drawing = 'Drawing',
}
