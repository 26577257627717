import { Component, EventEmitter, Input, Output } from '@angular/core';
import { cloneDeep, isEqual } from 'lodash';
import * as uuid from 'uuid';

interface Rule {
  type: 'rule';
  field: string;
  operator: string;
  value: string;
}

interface Group {
  type: 'group';
  operator: 'and' | 'or';
  items: (Rule | Group)[];
}

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss'],
})
export class TreeViewComponent {
  @Input() public configuredName!: string;

  @Input()
  set configuredFilter(form: any) {
    const value = form?.get(this.configuredName ?? '')?.value;

    if (value && !this.needToSkip) {
      const configuredFilterObject = JSON.parse(value);
      const newRules = this.extractRules(configuredFilterObject).map(
        (x) => x.id
      );
      const oldRules = this.extractRules(this.rootNode).map((x) => x.id);

      if (isEqual(newRules, oldRules)) return;

      if (Object.keys(configuredFilterObject).length) {
        this.rootNode = JSON.parse(value);
      }
    }
    this.needToSkip = false;
  }

  @Output()
  public onFilterChange = new EventEmitter();

  public options = ['==', '<=', '>', '<', '!=='];

  public pointsOption = ['>', '>=', '<', '<=', '==', '!=='];

  public ageOptions = ['>', '<', '>=', '<=', '=='];

  public tierOptions = ['==', '!==', 'IN', 'NOT IN'];

  public BDAfteroptions = ['==', '>=', '<=', '>', '<', '!=='];

  public isBithdayOptions = ['=='];

  public isMilitaryOptions = ['==', '!=='];

  
  public entityType = [
    'Points',
    'Tier',
    'Offer Type',
    'Points Earned Today',
    'Tier Points Earned Today',
    'Age',
    'Is Birthday',
    'Is Birthday Month',
    'Is X Days After Birthday',
    'Is X Days Before Birthday',
    'Gender',
    'Military status',
    'DaysFromEnrollment',
  ];

  public needToSkip = false;

  public rootNode: any = {
    type: 'condition',
    condition: 'and',
    rules: [],
  };

  constructor() {}

  private extractRules(obj: any): any[] {
    let rulesArray: any[] = [];

    return isEqual(obj, {}) ? [] : this.traverse(obj, rulesArray);
  }

  private traverse(obj: any, rulesArray: any[]): any[] {
    const rulesArrayClone = cloneDeep(rulesArray);

    return [
      ...rulesArrayClone,
      ...obj?.rules?.reduce((acc: any[], rule: any) => {
        if (rule.type === 'rule') {
          acc.push(rule);
        } else {
          acc = this.traverse(rule, cloneDeep(acc));
        }
        return acc;
      }, []),
    ];
  }

  public getOptions(type: string) {
    switch (type) {
      case 'Points':
        return this.pointsOption;
      case 'Age':
        return this.ageOptions;
      case 'Is Birthday':
        return this.isBithdayOptions;
      case 'Is Birthday Month':
        return this.isBithdayOptions;
      case 'Points Earned Today':
        return this.pointsOption;
      case 'Tier Points Earned Today':
        return this.pointsOption;
      case 'Is X Days After Birthday':
      case 'Is X Days Before Birthday':
        return this.BDAfteroptions;
      case 'Military status':
        return this.isMilitaryOptions;
      case 'Gender':
        return this.tierOptions;
      case 'DaysFromEnrollment':
        return this.ageOptions;
      default:
        return this.tierOptions;
    }
  }

  public changeRuleCondition(node: any, condition: string) {
    node.condition = condition;
    this.emitFilterChanges();
  }

  public addRuleSet(node: any): void {
    const newNode: any = {
      condition: 'and',
      rules: [],
    };
    node.rules.push(newNode);
    this.emitFilterChanges();
  }

  
  public deleteNode(node: any) {
    if (!node.id) {
      this.rootNode = undefined;
      this.emitFilterChanges();
      return;
    }
    if (!node.parentId) {
      const index = this.rootNode.rules.findIndex(
        (rule: any) => rule.id === node.id
      );
      (this.rootNode.rules as Array<any>).splice(index, 1);
    } else {
      this.removeChildById(this.rootNode, node.id);
    }
    this.emitFilterChanges();
  }

  removeChildById(obj: any, childId: any) {
    if (obj.rules) {
      for (let i = 0; i < obj.rules.length; i++) {
        const rule = obj.rules[i];

        if (rule.id === childId) {
          obj.rules.splice(i, 1);
          return true;
        }

        if (this.removeChildById(rule, childId)) {
          return true;
        }
      }
    }

    return false;
  }

  public addRule(node: any): void {
    const newNode: any = {
      parentId: node.id,
      id: uuid.v4(),
      field: '',
      operator: '',
      value: '',
      type: 'rule',
    };
    node.rules.push(newNode);
    this.emitFilterChanges();
  }

  public addCondition(node: any): void {
    const newNode: any = {
      parentId: node.id,
      id: uuid.v4(),
      type: 'condition',
      condition: 'and',
      rules: [],
    };
    node.rules.push(newNode);
    this.emitFilterChanges();
  }

  public emitFilterInputChanges(id: string): void {
    this.emitFilterChanges();
    this.saveAndRestoreFocus(id);
  }

  public emitFilterChanges(node?: any) {
    if (this.shouldBlockValueInput(node)) {
      node.value = true;
      node.operator = '==';
    }
    this.needToSkip = true;
    this.onFilterChange.emit(JSON.stringify(this.rootNode));
  }

  public shouldBlockValueInput(node: any) {
    if (!node) {
      return false;
    }
    switch (node.field) {
      case 'Is Birthday':
        return true;
      case 'Is Birthday Month':
        return true;
      default:
        return false;
    }
  }

  public getUniqueId(): number {
    return Math.floor(Math.random() * (100 - 1 + 1)) + 1;
  }

  private saveAndRestoreFocus(id: string): void {
    setTimeout(() => {
      const inputElement = document.getElementById(id) as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
      }
    }, 0);
  }
}
