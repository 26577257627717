export const GIFT_CARD_CONFIRM_HTML = `<div class="ofc-confirmation-container">
    <h2>Confirm purchase</h2>
    <p><b><span class="important-text">Important:</span> This purchase cannot be undone.</b></p>
    <p>Once confirmed, a unique link will be sent to your email on file: <span class="email">{{email}}</span></p>
    <p>Use the link to access the gift card catalog and select your eCode gift card.</p>
    <p class="underline-text">To update your email address, please visit the Player’s Club before making this purchase.</p>
    <p>Proceed with purchase?</p>
</div>`;

export const GIFT_CARD_CONFIRM_CSS = `.ofc-confirmation-container {
    font-family: Graphik;
    width: 100%;
    margin: 0 auto;
}
.ofc-confirmation-container h2 {
    color: rgba(41, 59, 85, 0.85);
    font-size: 56px;
    font-weight: 700;
}
.ofc-confirmation-container p {
    color: #1E2D43;
    font-size: 46px;
    font-weight: 500;
    margin-bottom: 40px;
}
.ofc-confirmation-container .important-text {
    color: #e74c3c;
    font-weight: bold;
}
.ofc-confirmation-container .email {
    font-weight: bold;
}
.ofc-confirmation-container .underline-text {
    text-decoration: underline;
}
@media (max-width: 1200px) {
    .ofc-confirmation-container h2 {
        font-size: 28px;
    }
    .ofc-confirmation-container p {
        font-size: 23px;
        margin-bottom: 0px;
    }
}`;

export const GIFT_CARD_DONE_HTML = `<div class="ofc-order-complete-container">
    <h2>Order Complete</h2>
    <p>Your confirmation number is <span class="confirmation-number">#{{confirmCode}}</span></p>
    <p>Check your email or scan the QR code to select a gift card</p>
</div>`;

export const GIFT_CARD_DONE_CSS = `.ofc-order-complete-container {
    text-align: center;
    font-family: Arial, sans-serif;
    margin: 0 auto;
    padding: 20px;
}

.ofc-order-complete-container h2 {
    font-weight: bold;
    color: #333;
}

.ofc-order-complete-container .confirmation-number {
    font-weight: bold;
}`;